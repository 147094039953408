import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 15,
    title: "Implementa il tuo Singleton con React",
    desc: "",
    img: "/blog-image/singleton.jpg",
    page: "blog/react-singleton",
    data: "13 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "React è una delle librerie JavaScript più popolari per la creazione di interfacce utente dinamiche e reattive.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        React è una delle librerie JavaScript
                                        più popolari per la creazione di
                                        interfacce utente dinamiche e reattive.
                                        Una delle sfide comuni nello sviluppo di
                                        applicazioni React è la gestione dello
                                        stato globale dell'applicazione. In
                                        molti casi, è necessario avere un
                                        oggetto Singleton - ovvero un oggetto
                                        unico condiviso tra tutti i componenti,
                                        per conservare lo stato globale. In
                                        questo articolo, esploriamo come
                                        implementare un Singleton con React!
                                    </p>

                                    <h3>Cos'è un Singleton?</h3>

                                    <p>
                                        Un Singleton è un design pattern
                                        creazionale che assicura ad una classe
                                        una sola istanza e fornisce un punto di
                                        accesso globale a quella istanza. Questo
                                        pattern è utile quando si desidera
                                        condividere una risorsa o uno stato
                                        unico in un'applicazione.
                                    </p>
                                    <p>
                                        Nel contesto di React, un Singleton può
                                        essere utilizzato per conservare lo
                                        stato globale dell'applicazione - come i
                                        dati dell'utente autenticato, le
                                        impostazioni globali o altre
                                        informazioni condivise tra i componenti.
                                    </p>

                                    <h5>Utilizzo del Context di React</h5>
                                    <p>
                                        React fornisce un modo nativo per
                                        gestire lo stato globale attraverso il
                                        Context API. Il Context API consente di
                                        condividere dati tra i componenti senza
                                        doverli passare manualmente attraverso
                                        le props - un modo efficace per
                                        implementare un Singleton in React.
                                    </p>
                                    <p>Ecco come si può procedere:</p>
                                    <h5>
                                        1. Creare un nuovo file per il tuo
                                        Singleton:
                                    </h5>
                                    <p>
                                        Per prima cosa, si crea un nuovo file
                                        per il tuo Singleton - ad esempio
                                        `appContext.js`. All'interno di questo
                                        file, inizializziamo il nostro Singleton
                                        utilizzando il Context API.
                                    </p>
                                    <h5>
                                        2. Importare le dipendenze necessarie:
                                    </h5>
                                    <p>
                                        All'inizio del file `appContext.js`,
                                        importare React e il modulo
                                        `createContext` dal pacchetto `react`.
                                    </p>

                                    <MyCoolCodeBlock
                                        code={`
    import React, { createContext, useContext, useState } from 'react';
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <h5>3. Creare il tuo Contesto:</h5>
                                    <p>
                                        Tramite la funzione `createContext` si
                                        crea il contesto e si definisce un
                                        componente Provider che condividerà i
                                        dati globali.
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    const AppContext = createContext();
                                            `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>4. Creare il proprio Provider:</h5>
                                    <p>
                                        Ora procediamo con la definizione di un
                                        componente Provider che verrà utilizzato
                                        per condividere i dati globali tra i
                                        componenti. Questo componente
                                        renderizzerà i suoi figli e fornirà loro
                                        il contesto.
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    export function AppProvider({ children }) {
        const [globalState, setGlobalState] = useState(initialState);

        return (
            <AppContext.Provider value={{ globalState, setGlobalState }}>
                {children}
            </AppContext.Provider>
        );
    }
                                            `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="p-3">
                                        Assicurati di passare `children` come
                                        props, in modo che i componenti figli
                                        possano essere avvolti dal tuo Provider.
                                    </p>
                                    <h5>5. Utilizzare il tuo Singleton:</h5>
                                    <p>
                                        Ora è possibile utilizzare il proprio
                                        Singleton in qualsiasi componente React.
                                        Per importare il contesto si utilizza la
                                        funzione `useContext`:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import { useContext } from 'react';
    import { AppContext } from './appContext';                                                                     
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="p-3">
                                        All'interno del componente, usare
                                        `useContext` per accedere allo stato
                                        globale e alle funzioni per modificarlo:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    const { globalState, setGlobalState } = useContext(AppContext);
                                    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>6. Utilizzare lo stato globale:</h5>
                                    <p className="p-3">
                                        Ora che si ha accesso allo stato globale
                                        del proprio Singleton, è possibile
                                        utilizzarlo nei componenti a piacere. Ad
                                        esempio, puoi leggere e scrivere dati
                                        globali:
                                    </p>

                                    <MyCoolCodeBlock
                                        code={`
    // Lettura dello stato globale
    const user = globalState.user;

    // Modifica dello stato globale
    setGlobalState({ ...globalState, user: newUser });
                                    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Questo è un esempio semplice di come
                                        puoi implementare un Singleton con React
                                        utilizzando il Context API. È un modo
                                        potente per gestire lo stato globale
                                        dell'applicazione e condividerlo tra i
                                        tuoi componenti in modo pulito e
                                        efficiente.
                                    </p>
                                    <h3>
                                        Quando utilizzare un Singleton in React?
                                    </h3>
                                    <p>
                                        È bene indicare che non è sempre
                                        necessario utilizzare un Singleton in
                                        React. È importante valutare
                                        attentamente l’effettivo bisogno di
                                        condivisione dei dati globali tra i
                                        componenti. Ecco alcune situazioni in
                                        cui si dovrebbe considerare l'utilizzo
                                        di un Singleton:
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                <b>Dati globali</b>: se hai dati
                                                che devono essere accessibili da
                                                molteplici parti
                                                dell'applicazione e devono
                                                essere mantenuti sincronizzati,
                                                un Singleton può essere utile.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Stato dell'autenticazione</b>
                                                : se devi gestire lo stato di
                                                autenticazione dell'utente in
                                                modo globale, un Singleton può
                                                semplificare il processo.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Impostazioni globali</b>: se
                                                hai impostazioni che influenzano
                                                l'aspetto o il comportamento
                                                dell'intera applicazione, un
                                                Singleton può essere una scelta
                                                ragionevole.
                                            </p>
                                        </li>
                                    </ul>
                                    <p>
                                        È sempre consigliabile valutare
                                        attentamente l'architettura
                                        dell'applicazione e cercare di mantenere
                                        lo stato globale il più semplice e
                                        trasparente possibile. L'uso eccessivo
                                        di un Singleton potrebbe portare a una
                                        complessità non necessaria
                                        nell'applicazione.
                                    </p>
                                    <p>
                                        L'implementazione di un Singleton con
                                        React utilizzando il Context API è un
                                        modo efficace per gestire lo stato
                                        globale dell'applicazione. Permette di
                                        condividere dati tra i componenti in
                                        modo pulito e efficiente, semplificando
                                        la gestione dello stato globale. È bene
                                        utilizzare questa tecnica con parsimonia
                                        e solo quando è realmente necessaria per
                                        risolvere problemi specifici
                                        nell'architettura dell'applicazione.
                                    </p>
                                    <p>
                                        Contattaci per una consulenza su React e
                                        migliora la gestione dello stato globale
                                        nella tua applicazione.
                                    </p>
                                    <p>Buon codice!</p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>
                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
